import React from "react"
import { graphql } from "gatsby"
import { Query } from "typings/graphql"
import { App } from "components/App/App"
import { renderBlock } from "components/Helpers/ComponentRenderer"
import { setTheme } from "helpers/Theme"
import { ModeType } from "typings/modules"

type PageProps = {
  data: Query
}

const PageTemplate = ({ data }: PageProps) => {
  const blocks = data.sanityPage.blocks
  const mode = data.sanityPage.navTheme as ModeType
  const theme = setTheme(
    data.sanityTypography,
    data.sanityDarkTheme,
    data.sanityLightTheme,
    data.sanityGlobalTheme
  )

  const seoData = {
    seo: data.sanityPage?.seo,
    path: data.sanityPage?.path,
  }

  return (
    <App theme={theme} layout="page" mode={mode} seo={seoData}>
      {blocks?.map((block, index) => renderBlock(block, index))}
    </App>
  )
}

export const pageQuery = graphql`
  query PageById($id: String!) {
    sanityPage(id: { eq: $id }) {
      navTheme
      title
      path
      blocks {
        ...ProductList
        ...HeroList
        ...FiftyFiftyList
        ...ValuePropList
        ...Markdown
        ...UGCList
        ...AccordionList
        ...FlexibleGridList
        ...YotpoReviewList
        ...ReviewList
        ...ProductCollection
        ...BlogCategoryList
        ...TileMatDesignTool
        ...LetterBoardDesignTool
      }
      seo {
        ...SanitySeo
      }
    }
    sanityTypography {
      ...Typography
    }
    sanityDarkTheme {
      ...DarkTheme
    }
    sanityLightTheme {
      ...LightTheme
    }
    sanityGlobalTheme {
      ...GlobalTheme
    }
  }
`

export default PageTemplate
